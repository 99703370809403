import React, { useContext, useEffect, createRef } from "react";
import { Button, Section, Text } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Helmet from "react-helmet"

const SuccessPasswordChange = () => {
    
    useEffect(() => {
        
    }, [])

    return (
        <>
            <Helmet>            
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Section className="green-bg text-center">
                <Container className="position-relative">
                    <Row className="justify-content-center mt-50 mb-50">
                        <Col sm={12}>
                            <h3>Password changed successfully.</h3>
                            <Button
                                onClick={() => navigate('/')}
                                className="mt-30 blue-bg"
                            >
                                Homepage
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

export default SuccessPasswordChange